///<reference path='../../../typings/tsd.d.ts' />
module ContactCtrl {
  'use strict';

  class ContactCtrl {

    ctrlName: string;
    result: string;
    resultMessage: string;
    formData: any;
    submitButtonDisabled: boolean;
    submitted: boolean;
    submit: any;
    serverUrl : any;
    canonicalUrl : any;

    // $inject annotation.
    // It provides $injector with information about dependencies to be injected into constructor
    // it is better to have it close to the constructor, because the parameters must match in count and type.
    // See http://docs.angularjs.org/guide/di
    public static $inject = ['$window','$http','serverUrl','$scope'];

    // dependencies are injected via AngularJS $injector
    constructor(private $window : ng.IWindowService,
                private $http : ng.IHttpService,
                private _serverUrl_ : any,
                private $scope: any) {

      // redirect page with .html extension to angular page
      if ($window.location.href.indexOf(".html") > 0) {
        $window.location.href = window.location.href.substring(0, $window.location.href.indexOf(".html"));
      }

      var vm = this;
      vm.ctrlName = 'ContactCtrl';
      vm.serverUrl = _serverUrl_;
      vm.canonicalUrl = vm.serverUrl.host + "/contact";

      // ToDo: use toastr for error messages

      $scope.result = 'hidden';
      $scope.resultMessage;
      $scope.formData; //formData is an object holding the name, email, subject, and message
      $scope.submitButtonDisabled = false;
      $scope.submitted = false; //used so that form errors are shown only after the form has been submitted
      $scope.submit = function(contactform, e) {

        $scope.submitted = true;
        $scope.submitButtonDisabled = true;

        if (contactform.$valid) {


          $http({

            method  : 'POST',
            url     : 'https://www.bergauf.ch/bin/contact-form.php',
            data    : $.param($scope.formData),  //param method from jQuery
            headers : { 'Content-Type': 'application/x-www-form-urlencoded' }  //set the headers so angular passing info as form data (not request payload)

          }).success(function(data: any){

            // console.log(data);

            if (data.success) { //success comes from the return json object

              $scope.submitButtonDisabled = true;
              $scope.resultMessage = data.message;
              $scope.result='bg-success';

            } else {

              $scope.submitButtonDisabled = false;
              $scope.resultMessage = data.message;
              $scope.result='bg-danger';

            }

          });

        } else {

          $scope.submitButtonDisabled = false;
          $scope.resultMessage = 'Bitte alle Pflichtfelder korrekt ausfüllen.';
          $scope.result='bg-danger';

        }

        e.preventDefault();

      }

    }
  }


  /**
   * @ngdoc object
   * @name home.controller:ContactCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('ContactCtrl', ContactCtrl);
}

