<!-- Update Metatags -->
<view-title>{{home.metaTitle}}</view-title>
<meta data-view-head name="description" content="{{home.metaDescription}}" />
<meta data-view-head property="og:title" content="{{home.metaTitle}}" />
<meta data-view-head property="og:description" content="{{home.metaDescription}}" />
<meta ng-if="home.ogImage" data-view-head property="og:image" content="{{home.serverUrl.data}}{{home.ogImage}}" />
<meta data-view-head property="og:url" content="{{home.canonicalUrl}}" />
<link data-view-head rel="canonical" href="{{home.canonicalUrl}}" />

<script type="application/ld+json" ng-bind="home.addBreadcrumb"></script>

<div class="row search">
  <div class="col-xs-9 col-sm-8 col-md-8 col-lg-8 form-group input-group-lg">
    <label for="search-input"><i class="fa fa-search fa-lg"></i></label>
    <input class="form-control search-input" type="text" placeholder="Suchen..." ng-model="home.query"
           ng-model-options="{debounce:500}" id="search-input" ng-change="home.triggerScroll();" aria-label="Bildergalerie suchen"/>
    <a id="search-clear" ng-click="search-input.$rollbackViewValue(); home.query=''" class="search-clear fa fa-times-circle fa-lg show"></a>
  </div>
  <div class="col-xs-3 col-sm-4 col-md-4 col-lg-4">
    <button type="button" class="btn btn-default btn-lg btn-block" data-title="Filtern / Suchen"
        data-template-url="home/views/searchfilters.tpl.html"
        data-animation="am-slide-right"
        data-controller-as="home"
        data-bs-aside="aside"
        data-container="body">
      <i class="fa fa-sliders fa-fw fa-lg"></i><span class="hidden-xs"> Filtern</span></button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-sm-8 col-md-8 col-lg-8">
    <h1 class="search-filter" ng-if="home.query || home.activity || home.country  || home.region">
      <span class="sr-only">Alle Bildergalerien mit </span>Suchfilter:
      <span ng-if="home.activity">"{{home.activity | convertIdToProperty:home.tags:'activities':'name'}}"</span>
      <span ng-if="home.country">"{{home.country | convertIdToProperty:home.tags:'countries':'name'}}"</span>
      <span ng-if="home.region">"{{home.region | convertIdToProperty:home.tags:'regions':'name'}}"</span>
      {{home.query}}
    </h1>
  </div>
  <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
    <p class="search-hits">Bildergalerien: {{filteredGalleries.length}} von {{home.galleries.length}}</p>
  </div>
</div>

<main>
<div class="row" ng-switch="home.hasInfiniteScroll">
  <div ng-switch-when="true" data-infinite-scroll='home.addMoreItems()' data-infinite-scroll-distance='2' data-infinite-scroll-listen-for-event="list:filtered">
    <div ng-repeat="gallery in home.items | filter:home.query | filter:home.activity:true | filter:home.country:true  | filter:home.region:true track by gallery.path" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <gallery-thumbnail gallery="{{gallery}}" ng-click="home.scrollToTop();"></gallery-thumbnail>
    </div>
  </div>
  <div ng-switch-default>
    <div ng-repeat="gallery in filteredGalleries = (home.galleries | orderBy:'-date' | filter:home.query | filter:home.activity:true | filter:home.country:true  | filter:home.region:true) track by gallery.path" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <gallery-thumbnail gallery="{{gallery}}"></gallery-thumbnail>
    </div>
  </div>
  <div ng-repeat="gallery in filteredGalleries = (home.galleries | orderBy:'-date' | filter:home.query | filter:home.activity:true | filter:home.country:true  | filter:home.region:true) track by gallery.path" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
  </div>
</div>
</main>
