///<reference path='../min.references.ts' />
module home {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($routeProvider: ng.route.IRouteProvider, $locationProvider: any) {
    $routeProvider
      .when('/index', {
        templateUrl: 'home/views/simple-home.tpl.html',
        controller: 'DefaultCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/suche', {
        templateUrl: 'home/views/home.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/bildergalerien-nach-laender/:countryId', {
        templateUrl: 'home/views/home.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/bildergalerien-nach-region/:regionId', {
        templateUrl: 'home/views/home.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/bildergalerien-nach-outdoor-aktivitaet/:activityId', {
        templateUrl: 'home/views/home.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/skitouren/:galleryLabel', {
        templateUrl: 'home/views/gallery-detail.tpl.html',
        controller: 'GalleryDetailCtrl',
        controllerAs: 'galleryDetail',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/bergtouren/:galleryLabel', {
        templateUrl: 'home/views/gallery-detail.tpl.html',
        controller: 'GalleryDetailCtrl',
        controllerAs: 'galleryDetail',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/velotouren/:galleryLabel', {
        templateUrl: 'home/views/gallery-detail.tpl.html',
        controller: 'GalleryDetailCtrl',
        controllerAs: 'galleryDetail',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      // travel galleries with a collection (index view)
      .when('/exped/gravelpacking-piemont', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/gravelpacking-schweiz', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-marokko', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-kambodscha', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-kolumbien', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-sulawesi', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-island', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-cabo-verde', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-kuba', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/reise-nach-myanmar', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/oman', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/skitouren-im-iran', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/eastgreenland06', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/maroc04', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/mtb-reise-ladakh', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/altiplano', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/exped/:galleryParent/:galleryLabel', {
        templateUrl: 'home/views/gallery-detail.tpl.html',
        controller: 'GalleryDetailCtrl',
        controllerAs: 'galleryDetail',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      // travel galleries without a collection (gallery view)
      .when('/exped/:galleryLabel', {
        templateUrl: 'home/views/gallery-detail.tpl.html',
        controller: 'GalleryDetailCtrl',
        controllerAs: 'galleryDetail',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/userhome', {
        templateUrl: 'home/views/userhome.tpl.html',
        controller: 'UserhomeCtrl',
        controllerAs: 'vm',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      // panorama galleries in a collection (index view)
      .when('/panoramas', {
        templateUrl: 'home/views/index.tpl.html',
        controller: 'IndexCtrl',
        controllerAs: 'home',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/panoramas/:galleryLabel', {
        templateUrl: 'home/views/gallery-detail.tpl.html',
        controller: 'GalleryDetailCtrl',
        controllerAs: 'galleryDetail',
        resolve: {
          'GalleryData': ['Repository', function (Repository: Home.Services.IRepository) {
            return Repository.loadGalleryData();
          }],
          'Tags': ['Tagging', function (Tagging: Home.Services.ITagging) {
            return Tagging.loadAllTags();
          }]
        }
      })
      .when('/impressum', {
        templateUrl: 'home/views/impressum.tpl.html',
        controller: 'DefaultCtrl',
        controllerAs: 'home'
      })
      .when('/contact', {
        templateUrl: 'home/views/contact.tpl.html',
        controller: 'ContactCtrl',
        controllerAs: 'contact'
      })
      .when('/favoriten', {
        templateUrl: 'home/views/bookmarks.tpl.html',
        controller: 'BookmarksCtrl',
        controllerAs: 'bookmarks'
      })

      // workarounds for issue #68 "Click on a navigation item gives Sizzle.error @ jquery.js:1458 errors
      // and issue #36 "Navbar: Dropdown do not always close after selection"
      .when('/gravelpacking-piemont', {
        redirectTo: '/exped/gravelpacking-piemont'
      })
      .when('/gravelpacking-schweiz', {
        redirectTo: '/exped/gravelpacking-schweiz'
      })
      .when('/reise-nach-marokko', {
        redirectTo: '/exped/reise-nach-marokko'
      })
      .when('/reise-nach-kambodscha', {
        redirectTo: '/exped/reise-nach-kambodscha'
      })
      .when('/reise-nach-kolumbien', {
        redirectTo: '/exped/reise-nach-kolumbien'
      })
      .when('/reise-nach-sulawesi', {
        redirectTo: '/exped/reise-nach-sulawesi'
      })
      .when('/reise-nach-island', {
        redirectTo: '/exped/reise-nach-island'
      })
      .when('/reise-nach-cabo-verde', {
        redirectTo: '/exped/reise-nach-cabo-verde'
      })
      .when('/reise-nach-kuba', {
        redirectTo: '/exped/reise-nach-kuba'
      })
      .when('/reise-nach-myanmar', {
        redirectTo: '/exped/reise-nach-myanmar'
      })
      .when('/oman', {
        redirectTo: '/exped/oman'
      })
      .when('/skitouren-im-iran', {
        redirectTo: '/exped/skitouren-im-iran'
      })
      .when('/eastgreenland06', {
        redirectTo: '/exped/eastgreenland06'
      })
      .when('/maroc04', {
        redirectTo: '/exped/maroc04'
      })
      .when('/mtb-reise-ladakh', {
        redirectTo: '/exped/mtb-reise-ladakh'
      })
      .when('/altiplano', {
        redirectTo: '/exped/altiplano'
      })

      // vanity url's
      .when('/skitouren', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/skitour'
      })
      .when('/bergtouren', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/bergtour'
      })
      .when('/velotouren', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/velotour'
      })
      .when('/exped', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/reise'
      })
      .when('/hochtouren', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/hochtour'
      })
      .when('/klettertouren', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/klettertour'
      })
      .when('/alpinwanderungen', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/alpinwanderung'
      })
      .when('/wanderungen', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/wanderung'
      })
      .when('/bike-and-hike', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/bike-and-hike'
      })
      .when('/mtb', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/mtb'
      })
      .when('/rennvelo', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/rennvelo'
      })
      .when('/gravel', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/gravel'
      })
      .when('/bikepacking', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/bikepacking'
      })
      .when('/ski-expeditionen', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/ski-expedition'
      })
      .when('/trekking', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/trekking'
      })
      .when('/reisen', {
        redirectTo: '/bildergalerien-nach-outdoor-aktivitaet/reise'
      })
      // default redirect
      .otherwise({
        redirectTo: '/suche'
      });

    // use the HTML5 History API
    $locationProvider.html5Mode(true);
  }
}
