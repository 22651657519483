///<reference path='../../min.references.ts' />
module HomeCtrl {
  'use strict';

  class HomeCtrl {

    ctrlName: string;
    currentDate: Date;
    currentFavorites: number;
    triggerScroll: any;
    scrollToTop: any;
    serverUrl: any;


    // $inject annotation.
    // It provides $injector with information about dependencies to be injected into constructor
    // it is better to have it close to the constructor, because the parameters must match in count and type.
    // See http://docs.angularjs.org/guide/di
    public static $inject = [
      '$location',
      'Authentication',
      '$cookies',
      '$rootScope',
      '$scope',
      'BookmarksService',
      'serverUrl',
      '$log',
      'Logger',
      '$anchorScroll',
    ];

    // dependencies are injected via AngularJS $injector
    constructor(private $location: ng.ILocationService,
                private Authentication: Authentication.IAuthentication,
                private $cookies: any,
                private $rootScope: any,
                private $scope: any,
                private BookmarksService: Home.Services.IBookmarksService,
                private _serverUrl_ : any,
                private $log: ng.ILogService,
                private logger : Logger.ILoggerService,
                private $anchorScroll: any) {
      var vm = this;
      vm.ctrlName = 'HomeCtrl';
      vm.serverUrl = _serverUrl_;
      vm.currentDate = new Date();
      vm.currentFavorites = BookmarksService.getNumberOfProducts();
/*
      $scope.$watch(() => { return BookmarksService.getNumberOfProducts(); }, (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.currentFavorites = BookmarksService.getNumberOfProducts();
        }
      });
*/
      // $rootScope.globals = this.$cookieStore.get('globals'); //read globals back from cookie as rootscope get's cleared on refresh!

      vm.triggerScroll = function() {
        // load all >500 galleries after a search filter have been applied
        for (var i = 0; i<7; i++) {
          $rootScope.$emit('list:filtered');
        }
        //console.log('triggerScroll in HomeCtrl called.');
      };

      vm.scrollToTop = function() {
        $anchorScroll();
        //console.log('scrollToTop in HomeCtrl called.');
      };
    }
  }


  /**
  * @ngdoc object
  * @name home.controller:HomeCtrl
  *
  * @description
  *
  */
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);
}
