<!-- Update Metatags -->
<view-title>{{home.title}}</view-title>
<meta data-view-head name="description" content="Übersicht zu den '{{home.title}}' Bildergalerien" />
<meta data-view-head property="og:title" content="{{home.title}}" />
<meta data-view-head property="og:description" content="Übersicht zu den '{{home.title}}' Bildergalerien" />
<meta ng-if="home.ogImage" data-view-head property="og:image" content="{{home.serverUrl.data}}{{home.ogImage}}" />
<meta data-view-head property="og:url" content="{{home.canonicalUrl}}" />
<link data-view-head rel="canonical" href="{{home.canonicalUrl}}" />

<script type="application/ld+json" ng-bind="home.addBreadcrumb"></script>

<!-- Page Heading -->
<main>
<div class="row">
  <div class="col-lg-12">
    <h1 class="page-header page-header-collections p-name">{{home.title}}</h1>
  </div>
</div>
<!-- /.row -->
<div class="row">
  <div data-ng-repeat="gallery in home.galleries | orderBy:home.orderby | filter:home.query | activity:home.themeFilter track by gallery.path" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
    <gallery-thumbnail gallery="{{gallery}}" countries="home.countries"></gallery-thumbnail>
  </div>
</div>
<!-- /.row -->
</main>
